<template>
    <CommonCard style="padding: 5%;">
      <v-row class="mb-10" dense align="center">
        <v-col cols="12" style="text-align: center;">
            <span class="titulo-header">Servicios Inbound</span>
          </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <BuscarLead />
        </v-col>
      </v-row>
    </CommonCard>
</template>

<script>
import BuscarLead from "./BuscarLead.vue";
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
export default {
  components: {
    BuscarLead,
    CommonCard
  },
  data() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
    puedeCrear() {
      const rolesValidos = [
        "ADMINCC",
        "MANAGERCC",
        "DIRECTO",
        "ADMIN",
        "ADMINAGENT",
        "MANAGER",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },
  methods: {
    leadCreado() {
      this.showCreacion = false;
      this.bus.$emit("reload");
    },
  },
};
</script>
