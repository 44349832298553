<template>
  <div>
    <v-card>
      <v-toolbar dense dark flat color="#00a7e4">
        <v-toolbar-title>
          <span>Resultados de búsqueda</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="leads"
          :headers="headers"
          no-data-text="Sin leads para mostrar"
          loading-text="Buscando leads..."
          :loading="loading"
          :footer-props="{
            'items-per-page-all-text': 'Todos',
            'items-per-page-text': 'Elementos por página',
          }"
        >
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} leads
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    leads: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: "id",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Medio",
          align: "center",
          value: "medio",
        },
        {
          text: "Ramo",
          align: "center",
          value: "ramo",
        },
        {
          text: "Canal",
          align: "center",
          value: "canal",
        },
        {
          text: "Producto",
          align: "center",
          value: "producto",
        },
        {
          text: "Teléfono",
          align: "center",
          value: "telefono",
        },
        {
          text: "Correo",
          align: "center",
          value: "correo",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
    };
  },
  methods: {
    changeRoute(item) {
      this.$router.push('/segurify/leads-segurify/lead/'+item.uuid_lead_segurify);
    },
  },
};
</script>
